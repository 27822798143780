
import { createTheme } from '@mui/material/styles';

const blueTheme = createTheme({
  palette: {
    primary: {
      main: '#283747', // Couleur bleue
    },
    secondary: {
      main: '#0056b3', // Couleur secondaire
    },
    background: {
      default: '#f0f8ff', // Fond bleu clair
    },
    text: {
      primary: '#ffffff', // Texte blanc
      black: '333333'
    },
  },
});

export default blueTheme;
