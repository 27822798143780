import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Button, Checkbox, FormControlLabel, FormGroup, useTheme } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ShuffleIcon from '@mui/icons-material/Shuffle';
import AutorenewIcon from '@mui/icons-material/Autorenew';

const specialCharacters = ['!', '@', '#', '$', '%', '^', '&', '*', '(', ')', '-', '_', '=', '+'];

export default function PasswordGenerator() {
  const theme = useTheme();
  const [length, setLength] = useState(25);
  const [passwordCount, setPasswordCount] = useState(1);
  const [includeNumbers, setIncludeNumbers] = useState(true); 
  const [includeSpecialChars, setIncludeSpecialChars] = useState(true); 
  const [selectedSpecialChars, setSelectedSpecialChars] = useState(
    specialCharacters.reduce((acc, char) => ({ ...acc, [char]: true }), {})
  );
  const [passwords, setPasswords] = useState([]);
  const [hoveredIndex, setHoveredIndex] = useState(null);  

  useEffect(() => {
    if (!includeSpecialChars) {
      setSelectedSpecialChars({});
    } else {
      setSelectedSpecialChars(specialCharacters.reduce((acc, char) => ({ ...acc, [char]: true }), {}));
    }
  }, [includeSpecialChars]);
  

  const handleSpecialCharChange = (char) => {
    setSelectedSpecialChars((prev) => ({ ...prev, [char]: !prev[char] }));
  };

  const generatePasswords = () => {
    const passwordsArray = [];
    const numbers = '0123456789';
    const lowercase = 'abcdefghijklmnopqrstuvwxyz';
    const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const specialChars = Object.keys(selectedSpecialChars).filter((char) => selectedSpecialChars[char]);

    for (let i = 0; i < passwordCount; i++) {
      let chars = lowercase + uppercase;
      if (includeNumbers) chars += numbers;
      if (includeSpecialChars && specialChars.length > 0) chars += specialChars.join('');

      let password = '';
      for (let j = 0; j < length; j++) {
        const randomChar = chars[Math.floor(Math.random() * chars.length)];
        password += randomChar;
      }
      passwordsArray.push(password);
    }
    setPasswords(passwordsArray);
  };

  const copyToClipboard = (password) => {
    navigator.clipboard.writeText(password);
    alert('Mot de passe copié !');
  };

  const shufflePassword = (password, index) => {
    const shuffledPassword = password.split('').sort(() => Math.random() - 0.5).join('');
    setPasswords((prev) => prev.map((p, i) => (i === index ? shuffledPassword : p)));
  };

  return (
    <Box sx={{ padding: { xs: '10px', sm: '20px' }, textAlign: 'center' }}>
      <Typography variant="h6" gutterBottom sx={{ color: theme.palette.primary.main, fontWeight: 'bold' }}>
        Générateur de mots de passe
      </Typography>
      <br />

      <TextField
        label="Longueur du mot de passe"
        type="number"
        value={length}
        onChange={(e) => setLength(Math.min(99, Math.max(4, Number(e.target.value))))}
        sx={{
          marginBottom: '10px',
          input: { color: theme.palette.primary.main },
          width: { xs: '100%', sm: 'auto' },
        }}
        InputProps={{
          inputProps: { min: 4, max: 99 },
        }}
      />

      <TextField
        label="Nombre de mots de passe"
        type="number"
        value={passwordCount}
        onChange={(e) => setPasswordCount(Math.min(20, Math.max(1, Number(e.target.value))))}
        sx={{
          marginBottom: '10px',
          input: { color: theme.palette.primary.main },
          width: { xs: '100%', sm: 'auto' },
        }}
        InputProps={{
          inputProps: { min: 1, max: 20 },
        }}
      />

      <FormGroup row sx={{ justifyContent: 'center', marginBottom: '10px' }}>
        <FormControlLabel
          control={<Checkbox checked={includeNumbers} onChange={() => setIncludeNumbers(!includeNumbers)} />}
          label="Inclure des chiffres"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={includeSpecialChars}
              onChange={() => setIncludeSpecialChars(!includeSpecialChars)}
            />
          }
          label="Inclure des caractères spéciaux"
        />
      </FormGroup>

      {includeSpecialChars && (
        <FormGroup row sx={{ justifyContent: 'center' }}>
          {specialCharacters.map((char) => (
            <FormControlLabel
              key={char}
              control={
                <Checkbox
                  checked={selectedSpecialChars[char] || false} 
                  onChange={() => handleSpecialCharChange(char)}
                />
              }
              label={char}
            />
          ))}
        </FormGroup>
      )}

      <Button
        variant="contained"
        color="primary"
        onClick={generatePasswords}
        sx={{ marginTop: '10px', marginBottom: '20px' }}
        startIcon={<AutorenewIcon />} 
      >
        Générer
      </Button>

      <Box sx={{ marginTop: '20px' }}>
        {passwords.map((password, index) => (
          <Box
            key={index}
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: '10px',
            }}
          >
            <Typography
              variant="body1"
              sx={{
                marginRight: '10px',
                fontFamily: 'monospace',
                wordBreak: 'break-all',
                filter: hoveredIndex !== index ? 'blur(4px)' : 'none',
                transition: 'filter 0.3s ease',
              }}
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
            >
              {password}
            </Typography>
            <Button onClick={() => copyToClipboard(password)} sx={{ marginBottom: { xs: '5px', sm: 0 } }}>
              <ContentCopyIcon />
            </Button>
            <Button onClick={() => shufflePassword(password, index)} sx={{ marginBottom: { xs: '5px', sm: 0 }, marginLeft: { sm: '10px' } }}>
              <ShuffleIcon />
            </Button>
          </Box>
        ))}
      </Box>
    </Box>
  );
}
