import { Typography} from '@mui/material';

export default function Formations() {
    return (
        <div className="">
            <Typography variant="h4" gutterBottom>
                Mes formations
            </Typography>
        </div>
    )
}