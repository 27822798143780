import React, { useState } from 'react';
import {
  Box,
  Typography,
  ListItem,
  LinearProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  useTheme
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import skillsData from '../data/skillsData';
import { motion, AnimatePresence } from 'framer-motion';

export default function Skills() {
  const theme = useTheme();
  const [progressValues, setProgressValues] = useState({});
  const [expandedIndex, setExpandedIndex] = useState(null);

  const handleAccordionMouseEnter = (categoryIndex) => {
    setExpandedIndex(categoryIndex);

    const newProgressValues = { ...progressValues };
    skillsData[categoryIndex].skills.forEach((skill, skillIndex) => {
      newProgressValues[`${categoryIndex}-${skillIndex}`] = 10;
      const targetCompletion = skill.completion;

      const interval = setInterval(() => {
        setProgressValues((prevValues) => {
          const currentValue = prevValues[`${categoryIndex}-${skillIndex}`] || 10;
          if (currentValue < targetCompletion) {
            return { ...prevValues, [`${categoryIndex}-${skillIndex}`]: Math.min(currentValue + 2, targetCompletion) };
          } else {
            clearInterval(interval);
            return prevValues;
          }
        });
      }, 20);
    });
  };

  const handleAccordionMouseLeave = () => {
    setExpandedIndex(null);
    const resetValues = { ...progressValues };
    skillsData.forEach((category, categoryIndex) => {
      category.skills.forEach((_, skillIndex) => {
        resetValues[`${categoryIndex}-${skillIndex}`] = undefined;
      });
    });
    setProgressValues(resetValues);
  };

  return (
    <Box sx={{ maxWidth: { xs: '100%', sm: '80%' }, textAlign: 'center', mx: 'auto', padding: '20px' }}>
      <Typography variant="h4" gutterBottom sx={{ color: theme.palette.primary.main, fontWeight: 'bold' }}>
        MES COMPETENCES
      </Typography>
      <br />
      <AnimatePresence>
        {skillsData.map((category, categoryIndex) => (
          <motion.div
            key={categoryIndex}
            initial={{ opacity: 0, y: 100 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: categoryIndex * 0.2 }}
            exit={{ opacity: 0, y: -200 }}
          >
            <Accordion
              expanded={expandedIndex === categoryIndex}
              onMouseEnter={() => handleAccordionMouseEnter(categoryIndex)}
              onMouseLeave={handleAccordionMouseLeave}
              sx={{ marginBottom: 2 }}
            >
              <AccordionSummary
                expandIcon={<ArrowDropDownIcon sx={{ color: 'white' }} />}
                aria-controls={`panel${categoryIndex}-content`}
                id={`panel${categoryIndex}-header`}
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  color: 'white',
                  '& .MuiAccordionSummary-expandIcon': {
                    color: 'lightgray',
                  },
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 2 }}>
                  {category.icon} 
                  <Typography variant="subtitle1" sx={{ marginLeft: 5 }}>
                    {category.category}
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Stack spacing={1}>
                  <AnimatePresence>
                    {category.skills.map((skill, skillIndex) => {
                      const key = `${categoryIndex}-${skillIndex}`;
                      return (
                        <motion.div
                          key={key}
                          initial={{ opacity: 0, x: -20 }}
                          animate={{ opacity: 1, x: 0 }}
                          exit={{ opacity: 0, x: 20 }}
                          transition={{ duration: 0.4 }}
                        >
                          <Box sx={{ display: 'block' }}>
                            <ListItem sx={{ display: 'block', mb: 1 }}>
                              <Typography
                                variant="body2"
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  fontWeight: progressValues[key] > 70 ? 'bold' : 'normal',
                                  color: theme.palette.primary.main,
                                }}
                              >
                                {skill.name}
                                {progressValues[key] > 70 && (
                                  <ThumbUpOffAltIcon
                                    sx={{
                                      marginLeft: 1,
                                      color: category.color,
                                    }}
                                  />
                                )}
                              </Typography>
                              <Box sx={{ position: 'relative', display: 'inline-flex', width: '100%' }}>
                                <LinearProgress
                                  variant="determinate"
                                  value={progressValues[key] || 0}
                                  sx={{
                                    height: 6,
                                    borderRadius: 4,
                                    width: '100%',
                                    bgcolor: '#e0e0e0',
                                    '& .MuiLinearProgress-bar': {
                                      backgroundColor: category.color,
                                    },
                                  }}
                                />
                                <Typography
                                  variant="caption"
                                  sx={{
                                    position: 'absolute',
                                    right: 0,
                                    top: -22,
                                    fontSize: '0.8rem',
                                    color: theme.palette.primary.main,
                                  }}
                                >
                                  {`${progressValues[key] || 0}%`}
                                </Typography>
                              </Box>
                            </ListItem>
                          </Box>
                        </motion.div>
                      );
                    })}
                  </AnimatePresence>
                </Stack>
              </AccordionDetails>
            </Accordion>
          </motion.div>
        ))}
      </AnimatePresence>
    </Box>
  );
}
