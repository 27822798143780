import { Box, } from '@mui/material';
import { useInView } from 'react-intersection-observer';
import Skills from '../Skills';

export default function HomeSkills () {
    const { ref: skillsRef, inView: skillsInView } = useInView({ threshold: 0.1 });
    
    return (
        <Box
            ref={skillsRef}
            className={`fade-in ${skillsInView ? 'visible' : ''}`}
            sx={{ marginBottom: 8, padding: 4,  borderRadius: '8px', width: '90%', textAlign: 'center' }}
        >
            <Skills />
        </Box>
    )
}