import { Box } from '@mui/material';
import { useInView } from 'react-intersection-observer';
import Curriculum from '../Curriculum';

export default function HomeCurriculum () {
    const { ref: curriculumRef, inView: curriculumInView } = useInView({ threshold: 0.1 });
    
    return (
        <Box
            ref={curriculumRef}
            className={`fade-in ${curriculumInView ? 'visible' : ''}`}
            sx={{ marginBottom: 8, padding: 4, borderRadius: '8px', width: '90%', textAlign: 'center' }}
        >
            <Curriculum />
        </Box>
    )
}