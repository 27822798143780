const experiencesData = [
  {
    label: 'Développeur FullStack chez Odigo',
    date: "De octobre 2022 à octobre 2024",
    description: `
      - WebRTC : Développement d’une application de test à la technologie WebRTC

      Développement d'une application web de diagnostic WebRTC React, MaterialUI

      Développement d’un serveur Websocket Node.js

      Containerisation des applications avec l'outil Docker

      Configuration de serveurs web avec des reverse proxy Amazon EC2, Nginx, Apache

      - Panoptes : Développement d’un tableau de bord qui présente des données métriques (KPI) des clients

      Modélisation de la base de données Merise

      Configuration du projet sous un environnement conteneurisé Docker, WSL 2

      Développement d’une application web Angular, MaterialUI

      Création d’une API REST Angular, Java Spring Boot

      Création de scripts en Shell pour l’importation massive quotidienne de données dans une base de données Shell, PostgreSQL

      Rédaction de documents techniques Confluence, Swagger

      - MyStats : Intégration de tableaux de bord dans la solution Odigo à destination des clients

      Développement de fonctions lambda afin de manipuler des fichiers Amazon S3, d’insérer des données dans une base de données Amazon Redshift serverless, de créer des ressources Quicksight Python, Amazon Lambda fonction, Amazon S3, Amazon Redshift serverless

      Création d’une API afin de générer un lien d’intégration Amazon API Gateway

      Configuration du service de génération de tableaux de bord Amazon Quicksight

      Intégration des tableaux de bord dans la solution Odigo en mode Iframe ou Embed Angular

      Rédaction de documents techniques Confluence, Swagger
    `,
    logoUrl: '/assets/experiences/odigo.png', 
  },
  {
    label: 'Freelance - Développeur Symfony',
    date: "De février 2023 à juin 2024",
    description:
      "Maintenance et amélioration de l'application back-office de l'entreprise Les Ingéniaux Symfony, PHP, MySQL, Doctrine 2, Twig, Bootstrap, HTML, CSS, JQuery",
    logoUrl: '/assets/experiences/les_ingeniaux.png', 
  },
  {
    label: 'Développeur Symfony',
    date: "De septembre 2021 à septembre 2022",
    description: `
      Développement de l’application backend dans un environnement conteneurisé Symfony, PHP, MySQL, Doctrine, Twig, Bootstrap, HTML, CSS, JQuery, Git, Linux

      Administration de la base de données MySQL

      Synchronisation des sessions d’ateliers en évènements Google Cloud Platform, Google Calendar

      Création de fonctionnalités d’envoi de mail et de gestion de modèle de mails Google Cloud Platform

      Synchronisation de l’application avec la boutique en ligne WordPress Wordpress, WooCommerce

      Utilisation de Google Charts pour la génération de tableaux de bords graphiques Javascript, Google Charts
    `,
    logoUrl: '/assets/experiences/les_ingeniaux.png', 
  },
  {
    label: 'Stage - Développeur Typescript',
    date: "De novembre 2020 à janvier 2021",
    description: `
      Développement d'une application web en TypeScript et Node.Js dédiée au diagnostic de la COVID-19.
    `,
    logoUrl: '/assets/experiences/opinaka.png', 
  },
];

export default experiencesData;
