import React, { useState } from 'react';
import { Box, Typography, Card, CardContent, Button, Chip, Stack, Tab, Tabs, useTheme, Pagination } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate } from 'react-router-dom';
import articles from '../data/articlesData';
import { motion } from 'framer-motion';

export default function Articles() {
  const theme = useTheme();
  const [selectedCategory, setSelectedCategory] = useState('tous');
  const [currentPage, setCurrentPage] = useState(1);
  const articlesPerPage = 4; 
  const navigate = useNavigate();

  const categories = ['tous', 'database', 'frameworks', 'testing', 'devops', 'cloud'];

  const handleCategoryChange = (event, newValue) => {
    setSelectedCategory(newValue);
    setCurrentPage(1); 
  };

  const filteredArticles = selectedCategory === 'tous'
    ? articles
    : articles.filter(article => article.category === selectedCategory);

  const totalPages = Math.ceil(filteredArticles.length / articlesPerPage);


  const displayedArticles = filteredArticles.slice((currentPage - 1) * articlesPerPage, currentPage * articlesPerPage);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <Box sx={{ maxWidth: { xs: '100%', sm: '80%' }, textAlign: 'center', mx: 'auto', padding: '20px' }}>
      <Typography variant="h4" gutterBottom sx={{ color: theme.palette.primary.main, fontWeight: 'bold' }}>
        MES ARTICLES
      </Typography>
      <br />
      <Tabs value={selectedCategory} onChange={handleCategoryChange} variant="scrollable" scrollButtons="auto">
        {categories.map((category) => (
          <Tab key={category} label={category.charAt(0).toUpperCase() + category.slice(1)} value={category} />
        ))}
      </Tabs>
      <Stack spacing={3} sx={{ marginTop: '20px' }}>
        {displayedArticles.map((article) => (
          <motion.div
            key={article.id}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
          >
            <motion.div
              whileHover={{
                scale: 1.05, 
                boxShadow: '0px 8px 30px rgba(0, 0, 0, 0.1)', 
              }}
              whileTap={{
                scale: 0.98, 
              }}
            >
              <Card sx={{
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                transition: 'transform 1s ease-in-out', 
              }}>
                <CardContent>
                  <Typography variant="h5" sx={{ color: theme.palette.primary.main }}>{article.title}</Typography>
                  <Typography variant="body2" sx={{ color: theme.palette.primary.main }}>{article.description}</Typography>

                  <Stack
                    direction="row"
                    spacing={1}
                    sx={{ marginTop: '10px', flexWrap: 'wrap', rowGap: 1 }}
                  >
                    {article.technologies.map((tech, idx) => (
                      <Chip
                        key={idx}
                        label={tech}
                        sx={{
                          backgroundColor: theme.palette.primary.main,
                          color: 'white',
                          '&:hover': {
                            backgroundColor: theme.palette.primary.dark,
                          }
                        }}
                      />
                    ))}
                  </Stack>

                  <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={2}
                    sx={{
                      marginTop: '10px',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Button
                      variant="contained"
                      onClick={() => navigate(`/articles/${article.id}`)}
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        backgroundColor: theme.palette.primary.main,
                        alignItems: 'center',
                        gap: 1,
                      }}
                    >
                      <VisibilityIcon sx={{ marginRight: 1 }} />
                      Voir l'article complet
                    </Button>
                  </Stack>
                </CardContent>
              </Card>
            </motion.div>
          </motion.div>
        ))}
      </Stack>

      <Box sx={{ marginTop: '20px' }}>
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
          size="large"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            '& .MuiPaginationItem-root': {
              color:  theme.palette.primary.main, 
            },
            '& .MuiPaginationItem-root.Mui-selected': {
              color: 'white', 
            },
          }}
        />
      </Box>
    </Box>
  );
}
