import React, { useState } from 'react';
import { Box, Typography, useTheme, Grid, Card, CardContent, IconButton } from '@mui/material';
import WeatherIcon from '@mui/icons-material/Cloud';
import PasswordIcon from '@mui/icons-material/VpnKey';
import Weather from './ToolComponents/Weather';
import PasswordGenerator from './ToolComponents/PasswordGenerator';

const toolsList = [
  { label: 'Météo', component: <Weather />, icon: <WeatherIcon /> },
  { label: 'Générateur de mot de passe', component: <PasswordGenerator />, icon: <PasswordIcon /> },
];

export default function Tools() {
  const theme = useTheme();
  const [selectedTool, setSelectedTool] = useState(null);

  const handleToolSelect = (tool) => {
    setSelectedTool(tool);
  };

  return (
    <Box sx={{ padding: '20px', textAlign: 'center' }}>
      <Typography variant="h4" gutterBottom sx={{ color: theme.palette.primary.main, fontWeight: 'bold' }}>
        Mes outils
      </Typography>

      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} sm={4}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
            {toolsList.map((tool) => (
              <Card
                key={tool.label}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '10px',
                  boxShadow: 3,
                  '&:hover': {
                    transform: 'scale(1.05)',
                    transition: '0.3s',
                    boxShadow: 6,
                    backgroundColor: theme.palette.action.hover,
                  },
                }}
                onClick={() => handleToolSelect(tool)}
              >
                <IconButton sx={{ fontSize: '30px' }}>
                  {tool.icon}
                </IconButton>
                <CardContent>
                  <Typography variant="h6" sx={{color: theme.palette.primary.main}}>{tool.label}</Typography>
                </CardContent>
              </Card>
            ))}
          </Box>
        </Grid>

        <Grid item xs={12} sm={8}>
          <Box sx={{ padding: '20px', bgcolor: theme.palette.background.paper, borderRadius: '8px' }}>
            {selectedTool ? selectedTool.component : <Typography>Veuillez sélectionner un outil</Typography>}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
