import React from 'react';
import { Box, Typography, Grid, Card, CardContent, CardMedia, Button, Chip, Stack, useTheme } from '@mui/material';
import GitHubIcon from '@mui/icons-material/GitHub';
import Carousel from 'react-bootstrap/Carousel';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { motion } from 'framer-motion'; 
import projects from '../data/projectsData';

export default function Projects() {
  const theme = useTheme(); 

  return (
    <Box sx={{ padding: '20px', textAlign: 'center' }}>
      <Typography variant="h4" gutterBottom sx={{ color: theme.palette.primary.main, fontWeight: 'bold', textTransform: 'uppercase' }}>
        MES PROJETS
      </Typography>
      <br />
      <Grid container spacing={3}>
        {projects.map((project, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <motion.div
              initial={{ opacity: 0, transform: 'scale(0.95)' }}
              animate={{ opacity: 1, transform: 'scale(1)' }}
              transition={{ duration: 0.5, ease: 'easeOut' }}
            >
              <Card sx={{ boxShadow: 3, borderRadius: 2, '&:hover': { boxShadow: 10 } }}> 
                <CardMedia
                  component="img"
                  alt={project.title}
                  height="150"
                  image={project.image}
                  sx={{ objectFit: 'cover' }}
                />
                <CardContent>
                  <Typography variant="h5" sx={{color: theme.palette.primary.main}}>{project.title}</Typography>
                  <Typography variant="body2" sx={{color: theme.palette.primary.main}}>{project.description}</Typography>

                  <Stack
                    direction="row"
                    spacing={1}
                    sx={{ marginTop: '10px', flexWrap: 'wrap', rowGap: 1 }}
                  >
                    {project.technologies.map((tech, idx) => (
                      <Chip
                        key={idx}
                        label={tech}
                        sx={{
                          backgroundColor: theme.palette.primary.main,  
                          color: 'white', 
                          '&:hover': {
                            backgroundColor: theme.palette.primary.dark, 
                          },
                        }}
                      />
                    ))}
                  </Stack>

                  <Stack
                    direction={{ xs: 'column', sm: 'row' }}  
                    spacing={2}  
                    sx={{
                      marginTop: '10px',
                      justifyContent: 'center',
                      alignItems: 'center', 
                    }}
                  >
                    <Button
                      variant="contained"
                      href={project.githubLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{
                        display: 'flex',
                        backgroundColor: 'white',
                        color: theme.palette.primary.main,
                        justifyContent: 'center',
                        '&:hover': {
                          backgroundColor: theme.palette.primary.light,
                          color:'white'
                        },
                      }}
                    >
                      Voir le code sur <GitHubIcon sx={{ marginLeft: 1 }} />
                    </Button>

                    <Button
                      variant="contained"
                      href={project.demoLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        backgroundColor: theme.palette.primary.main,
                        color: 'white',
                        alignItems: 'center', 
                        gap: 1, 
                        '&:hover': {
                          backgroundColor: theme.palette.primary.dark,
                        },
                      }}
                    >
                      <VisibilityIcon sx={{ marginRight: 1 }} /> 
                      Voir une démonstration
                    </Button>
                  </Stack>
                  <Carousel
                    style={{
                      marginTop: '20px',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    }}
                    nextIcon={<span className="carousel-control-next-icon" style={{ filter: 'invert(100%)' }} />}
                    prevIcon={<span className="carousel-control-prev-icon" style={{ filter: 'invert(100%)' }} />}
                    indicators={true}
                    interval={4000}
                  >
                    {project.screenshots.map((screenshot, idx) => (
                      <Carousel.Item key={idx}>
                        <CardMedia
                          component="img"
                          alt={`Screenshot ${idx + 1} de ${project.title}`}
                          height="200"
                          image={screenshot}
                          sx={{ objectFit: 'cover' }}
                        />
                      </Carousel.Item>
                    ))}
                  </Carousel>

                </CardContent>
              </Card>
            </motion.div>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
