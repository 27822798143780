import { useInView } from 'react-intersection-observer';
import Contact from '../Contact';
import { Box} from '@mui/material';

export default function HomeContact() {
    const { ref: contactRef, inView: contactInView } = useInView({ threshold: 0.1 });

    return (
        <Box
            ref={contactRef}
            className={`fade-in ${contactInView ? 'visible' : ''}`}
            sx={{ marginBottom: 8, padding: 4,  borderRadius: '8px', width: '90%', textAlign: 'center' }}
        >
            <Contact />
        </Box>
    )
}