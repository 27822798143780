import React, { useState } from 'react';
import { AppBar, Toolbar, Box, Typography, IconButton, Drawer, List, ListItem, Button, useTheme, useMediaQuery, ListItemText } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import { Home as HomeIcon, Work as WorkIcon, Build as BuildIcon, Folder as FolderIcon, Markunread as MarkunreadIcon, Description as DescriptionIcon, Book as BookIcon, Construction as ConstructionIcon } from '@mui/icons-material';
import { motion } from 'framer-motion'

export default function Nav() {
  const theme = useTheme();
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [logoAnimation, setLogoAnimation] = useState(false);

  const toggleDrawer = (open) => {
    setDrawerOpen(open);
  };

  const menuItems = [
    { text: 'Accueil', path: '/', icon: <HomeIcon /> },
    { text: 'Mes Expériences', path: '/experiences', icon: <WorkIcon /> },
    { text: 'Mes Compétences', path: '/competences', icon: <BuildIcon /> },
    { text: 'Mes Projets', path: '/projects', icon: <FolderIcon /> },
    { text: 'Mes Articles', path: '/articles', icon: <BookIcon /> },
    { text: 'Mes Outils', path: '/outils', icon: <ConstructionIcon /> },
    { text: 'Me Contacter', path: '/contact', icon: <MarkunreadIcon /> },
    { text: 'Mon CV', path: '/curriculum', icon: <DescriptionIcon /> },
  ];

  const handleLogoClick = () => {
    setLogoAnimation(true);
    setTimeout(() => setLogoAnimation(false), 500);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ backgroundColor: theme.palette.primary.main, zIndex: 1 }}>
        <Toolbar sx={{ justifyContent: isMobile ? 'center' : 'space-between' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center' }}>
              <motion.div
                onClick={handleLogoClick}
                animate={logoAnimation ? { scale: 0.6, rotate: 30 } : { scale: 1, rotate: 0 }}
                transition={{ duration: 0.5 }}
              >
                <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
                  <img
                    src="/assets/logo.png"
                    alt="Logo"
                    style={{
                      width: isMobile ? '250px' : '100%', 
                      height: 'auto',
                    }}
                  />
                </Link>
              </motion.div>
            </Typography>

            {isMobile && (
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={() => toggleDrawer(true)}
                sx={{
                  position: 'absolute',
                  top: '10px', 
                  right: '10px', 
                }}
              >
                <MenuIcon />
              </IconButton>
            )}
          </Box>

          {!isMobile && (
            <Box>
              {menuItems.map((item) => (
                <Button
                  color="inherit"
                  key={item.text}
                  startIcon={item.icon}
                  sx={{ '&:hover': { transform: 'scale(1.10)', transition: 'transform 0.6s' } }}
                >
                  <Link to={item.path} style={{ textDecoration: 'none', color: 'white' }}>
                    {item.text}
                  </Link>
                </Button>
              ))}
            </Box>
          )}
        </Toolbar>
      </AppBar>

      {isMobile && (
        <Drawer anchor="left" open={isDrawerOpen} onClose={() => toggleDrawer(false)}>
          <List sx={{ height: '100%', backgroundColor: theme.palette.primary.main }}>
            {menuItems.map((item) => (
              <ListItem button key={item.text} onClick={() => toggleDrawer(false)} sx={{ '&:hover': { transform: 'scale(1.05)', transition: 'transform 0.2s' } }}>
                <Link to={item.path} style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}>
                  {item.icon}
                  <ListItemText primary={item.text} sx={{ marginLeft: 1 }} />
                </Link>
              </ListItem>
            ))}
          </List>
        </Drawer>
      )}
    </Box>
  );
}
