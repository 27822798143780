import articles from '../../data/articlesData';
import ListIcon from '@mui/icons-material/List'; 
import { Box, Typography, Button, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useInView } from 'react-intersection-observer';

export default function HomeArticles() {
    const navigate = useNavigate();
    const { ref: articlesRef, inView: articlesInView } = useInView({ threshold: 0.1 });
    const theme = useTheme();

    return (
        <Box
            sx={{ 
                marginBottom: 8, 
                padding: 4, 
                borderRadius: '8px', 
                width: '90%', 
                textAlign: 'center',
            }} 
        >
            <Typography 
                variant="h4" 
                gutterBottom 
                sx={{ 
                    color: theme.palette.primary.main, 
                    fontWeight: 'bold', 
                    marginBottom: 2, 
                }}
            >
                MES ARTICLES
            </Typography>


            <Box
                ref={articlesRef} 
                className={`fade-in ${articlesInView ? 'visible' : ''}`}
                sx={{ 
                    padding: 2, 
                    borderRadius: '8px', 
                    maxHeight: '600px', 
                    overflowY: 'auto', 
                }} 
            >
                {articles.map(article => (
                    <Box key={article.id} sx={{ marginBottom: 2, padding: 2, borderRadius: '4px', textAlign: 'left' }}>
                        <Typography variant="h6">{article.title}</Typography>
                        <Typography variant="body2">{article.description}</Typography>
                        <Button 
                            variant="contained" 
                            onClick={() => navigate(`/articles/${article.id}`)} 
                            sx={{ marginTop: 1, backgroundColor: theme.palette.primary.main, color: 'white' }}
                            endIcon={<ArrowForwardIcon />} 
                        >
                            Voir l'article
                        </Button>
                    </Box>
                ))}
            </Box>

            <Button 
                variant="outlined" 
                onClick={() => navigate('/articles')} 
                sx={{ marginTop: 2, backgroundColor: theme.palette.primary.main, color: 'white' }}
                startIcon={<ListIcon />} 
            >
                Voir tous les articles
            </Button>
        </Box>
    );
}
