import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import articles from '../data/articlesData';
import { Box, Typography, Button, Card, CardContent, Stack, useTheme } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack'; 
import GitHubIcon from '@mui/icons-material/GitHub';

export default function ArticleDetail() {
  const theme = useTheme(); 
  const { id } = useParams();
  const navigate = useNavigate();
  const article = articles.find(article => article.id === parseInt(id));

  if (!article) {
    return (
      <Box sx={{ padding: '10px', textAlign: 'center' }}>
        <Typography variant="h6">Article non trouvé</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ maxWidth: { xs: '100%', sm: '70%' }, mx: 'auto', padding: '20px' }}>
      <Button
        variant="outlined"
        onClick={() => navigate('/articles')}
        sx={{ 
          marginBottom: '20px', 
          color: 'white', 
          borderColor: theme.palette.primary.main, 
          backgroundColor: theme.palette.primary.main, 
          '&:hover': { 
            backgroundColor: 'darkgray',
            borderColor: 'darkgray',
          }
        }}
      >
        <ArrowBackIcon sx={{ marginRight: '8px' }} /> 
        Retour à la liste des articles
      </Button>
      <Card
        sx={{
          backgroundColor: 'rgba(255, 255, 255, 0.8)', 
          boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)' 
        }}
      >
        <CardContent>
          <Typography variant="body2" sx={{ marginBottom: '5px', color: 'gray' }}>
            Date de création : {new Date(article.creationDate).toLocaleDateString('fr-FR', {
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            })}
          </Typography>
          <Typography variant="body2" sx={{ marginBottom: '15px', color: 'gray' }}>
            Dernière modification : {new Date(article.lastModifiedDate).toLocaleDateString('fr-FR', {
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            })}
          </Typography>
          
          <Typography variant="h4" gutterBottom>
            {article.title}
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: '10px', color: theme.palette.primary.main }} 
            dangerouslySetInnerHTML={{ __html: article.content }} 
          />
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
            <Stack direction="row" spacing={1}>
              <Typography variant="body2" sx={{ color: theme.palette.primary.main }}>Technologies :</Typography>
              {article.technologies.map((tech, index) => (
                <Typography key={index} variant="body2" sx={{ color: theme.palette.primary.main }}> 
                  {tech}
                </Typography>
              ))}
            </Stack>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              variant="contained"
              
              href={article.githubLink}
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                backgroundColor:theme.palette.primary.main,
                color:'white'
              }}
            >
              Voir le code sur <GitHubIcon />
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}
