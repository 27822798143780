import React from 'react';
import { Box} from '@mui/material';
import HomeArticles from './HomeComponents/HomeArticles';
import HomeAbout from './HomeComponents/HomeAbout';
import HomeExperiences from './HomeComponents/HomeExperiences';
import HomeProjects from './HomeComponents/HomeProjects';
import HomeSkills from './HomeComponents/HomeSkills';
import HomeCurriculum from './HomeComponents/HomeCurriculum';
import HomeContact from './HomeComponents/HomeContact';
import './Home.css'; 

export default function Home() {

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '20px',
                textAlign: 'center',
            }}
        >

            <HomeAbout />
            <HomeExperiences />
            <HomeProjects />
            <HomeSkills />
            <HomeArticles />
            <HomeCurriculum />
            <HomeContact />
        </Box>
    );
}
