import { DiAngularSimple, DiReact, DiJava, DiJavascript1, DiGit, DiPhp, DiHtml5, DiDocker, DiPython } from "react-icons/di";
import Typical from 'react-typical';
import { Box, Typography, useTheme } from '@mui/material';
import { motion } from 'framer-motion';

export default function HomeAbout() {
    const theme = useTheme();

    const icons = [
        DiHtml5, DiAngularSimple, DiReact, DiJava,
        DiJavascript1, DiGit, DiPhp, DiPython,
        DiDocker
    ];

    return (
        <Box
            sx={{
                marginTop: 10,
                marginBottom: 4,
                padding: 8,
                borderRadius: '8px',
                width: '90%',
                textAlign: 'center',
                backgroundSize: '50%',
                backgroundPosition: 'center',
                transition: 'background-size 3s ease-in-out, filter 3s ease-in-out',
                color: theme.palette.primary.main,
                '&:hover': {
                    backgroundSize: '80%',
                },
            }}
        >
            <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1 }}
            >
                <Typography variant="h2" sx={{ fontSize: { xs: '2rem', sm: '3rem', md: '4rem' } }}>
                    <Typical steps={['Nabil KAJEIOU', 1000]} loop={1} wrapper="span" />
                </Typography>
            </motion.div>

            <motion.div
                initial={{ opacity: 0, y: 30 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1, delay: 0.5 }}
            >
                <Typography variant="h4" sx={{ marginY: 2, height: { xs: '150px', sm: '120px', md: '80px' }, lineHeight: { xs: '40px', sm: '65px', md: '40px' } }}>
                    <Typical steps={["Développeur Full Stack 🚀", 1000]} loop={1} wrapper="span" />
                </Typography>
            </motion.div>

            <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1, delay: 1 }}
            >
                <Typography variant="body1" sx={{ maxWidth: '600px', margin: '0 auto' }}>
                    Je suis un développeur full-stack passionné avec une expérience de 4 ans dans le développement web. <br />
                    <br />
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            gap: 2,
                            flexWrap: 'wrap'
                        }}
                    >
                        {icons.map((Icon, index) => (
                            <motion.div
                                key={index}
                                initial={{ scale: 1 }}
                                animate={{ scale: 1.3 }}
                                transition={{
                                    duration: 1,
                                    delay: 2 + index * 0.5, 
                                    repeat: Infinity,
                                    repeatType: "reverse"
                                }}
                            >
                                <Icon size="40" />
                            </motion.div>
                        ))}
                    </Box>
                </Typography>
            </motion.div>

            <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1, delay: 1.5 }}
            >
                <Typography variant="body1" sx={{ maxWidth: '600px', margin: '20px auto' }}>
                    Je suis familiarisé avec le déploiement d'applications sur AWS et l'utilisation de Docker. 
                    <br />J'adore apprendre de nouvelles technologies et m'efforce d'améliorer continuellement mes compétences.
                </Typography>
            </motion.div>

            
        </Box>
    );
}
