const articles = [
    {
        id: 1,
        title: "Introduction à MongoDB",
        githubLink:"https://github.com/kajeiou/",
        description: "Introduction aux bases de données NoSQL avec MongoDB.",
        creationDate: "2024-11-04", 
        lastModifiedDate: "2024-11-04",
        technologies: ["MongoDB", "NoSQL"],
        content: `
            <p>MongoDB est une base de données NoSQL orientée document qui permet de stocker et de gérer des données de manière flexible. 
            Dans cet article, nous allons explorer ses caractéristiques, son installation et des exemples d'utilisation.</p>
            <h2>Caractéristiques de MongoDB</h2>
            <ul>
                <li><strong>Scalabilité horizontale :</strong> MongoDB peut être facilement distribué sur plusieurs serveurs.</li>
                <li><strong>Documents JSON :</strong> Les données sont stockées sous forme de documents BSON, ce qui facilite la manipulation des données.</li>
                <li><strong>Indexation :</strong> MongoDB permet une indexation efficace pour améliorer les performances des requêtes.</li>
            </ul>
            <h2>Installation</h2>
            <p>Pour installer MongoDB, suivez ces étapes :</p>
            <ol>
                <li>Téléchargez le package d'installation à partir du site officiel.</li>
                <li>Suivez les instructions pour votre système d'exploitation.</li>
                <li>Configurez le service pour qu'il démarre automatiquement.</li>
            </ol>
            <h2>Exemple d'utilisation</h2>
            <p>Voici un exemple de code pour se connecter à MongoDB :</p>
            <pre>
                <code>
                    const { MongoClient } = require('mongodb');
                    const url = 'mongodb://localhost:27017';
                    const client = new MongoClient(url);
                    async function run() {
                        await client.connect();
                        const database = client.db('testdb');
                        const collection = database.collection('testcollection');
                        // Ajoutez des documents
                        await collection.insertOne({ name: "Exemple", value: 42 });
                    }
                    run().catch(console.dir);
                </code>
            </pre>
        `,
        category: "database",
    },
    {
        id: 2,
        title: "Déployer une application sur AWS",
        githubLink:"https://github.com/kajeiou/",
        description: "Déployer des applications sur AWS.",
        creationDate: "2024-11-04", 
        lastModifiedDate: "2024-11-04",
        technologies: ["AWS", "Cloud"],
        content: `
            <p>Ce guide vous montrera comment déployer une application web sur Amazon Web Services, 
            en utilisant des services comme EC2, S3 et RDS.</p>
            <h2>Étapes du déploiement</h2>
            <ol>
                <li><strong>Création d'une instance EC2 :</strong> Accédez à la console AWS et lancez une nouvelle instance EC2.</li>
                <li><strong>Configuration du groupe de sécurité :</strong> Assurez-vous d'autoriser le trafic HTTP et HTTPS.</li>
                <li><strong>Déploiement du code :</strong> Utilisez SFTP ou Git pour transférer votre code vers l'instance EC2.</li>
                <li><strong>Configuration de la base de données :</strong> Si nécessaire, configurez RDS pour gérer votre base de données.</li>
                <li><strong>Tests :</strong> Testez votre application pour vous assurer qu'elle fonctionne correctement.</li>
            </ol>
            <h2>Exemple de code</h2>
            <p>Voici un exemple de code pour déployer une application Node.js sur EC2 :</p>
            <pre>
                <code>
                    const express = require('express');
                    const app = express();
                    const PORT = process.env.PORT || 3000;
                    app.get('/', (req, res) => {
                        res.send('Bonjour, monde !');
                    });
                    app.listen(PORT, () => {
                        console.log(\`Serveur en cours d'exécution sur le port \${PORT}\`);
                    });
                </code>
            </pre>
        `,
        category: "cloud",
    },
    {
        id: 3,
        title: "Utilisation de React pour le développement front-end",
        githubLink:"https://github.com/kajeiou/",
        description: "Utiliser React pour construire des applications web.",
        creationDate: "2024-11-04", 
        lastModifiedDate: "2024-11-04",
        technologies: ["React"],
        content: `
            <p>React est une bibliothèque JavaScript pour construire des interfaces utilisateur. 
            Cet article couvre les concepts de base de React, y compris les composants, l'état et le cycle de vie.</p>
            <h2>Installation de React</h2>
            <p>Pour commencer avec React, installez-le via npm :</p>
            <pre>
                <code>
                    npx create-react-app mon-projet
                </code>
            </pre>
            <h2>Composants React</h2>
            <p>Les composants sont les éléments de base d'une application React. Voici un exemple de composant fonctionnel :</p>
            <pre>
                <code>
                    export default function HelloWorld() {
                        return "Bonjour, le monde !";
                    }
                </code>
            </pre>
            <h2>Gestion de l'état</h2>
            <p>Utilisez le hook <code>useState</code> pour gérer l'état dans les composants fonctionnels :</p>
            <pre>
                <code>
                    import React, { useState } from 'react';
                    function Counter() {
                        const [count, setCount] = useState(0);
                        return "Vous avez cliqué {count} fois";
                    }
                </code>
            </pre>
            <h2>Cycle de vie des composants</h2>
            <p>Utilisez le hook <code>useEffect</code> pour gérer les effets secondaires :</p>
            <pre>
                <code>
                    import React, { useEffect } from 'react';
                    function MyComponent() {
                        useEffect(() => {
                            console.log('Component mounted');
                            return () => {
                                console.log('Component unmounted');
                            };
                        }, []);
                        return <>Mon composant</>;
                    }
                </code>
            </pre>
        `,
        category: "frameworks",
    },
    {
        id: 4,
        title: "Tests unitaires avec Jest",
        githubLink:"https://github.com/kajeiou/",
        description: "Écrire des tests unitaires avec Jest",
        technologies: ["Jest", "Testing"],
        creationDate: "2024-11-04", 
        lastModifiedDate: "2024-11-04",
        content: `
            <p>Jest est un framework de test JavaScript populaire. Dans cet article, nous allons voir comment configurer Jest 
            et écrire des tests unitaires pour nos applications.</p>
            <h2>Installation de Jest</h2>
            <p>Installez Jest en utilisant npm :</p>
            <pre>
                <code>
                    npm install --save-dev jest
                </code>
            </pre>
            <h2>Écriture d'un test simple</h2>
            <p>Voici un exemple de test unitaire avec Jest :</p>
            <pre>
                <code>
                    test('additionne 1 + 2 pour donner 3', () => {
                        expect(1 + 2).toBe(3);
                    });
                </code>
            </pre>
            <h2>Tester des fonctions</h2>
            <p>Vous pouvez également tester des fonctions comme ceci :</p>
            <pre>
                <code>
                    function sum(a, b) {
                        return a + b;
                    }
                    test('la fonction sum additionne correctement', () => {
                        expect(sum(1, 2)).toBe(3);
                    });
                </code>
            </pre>
            <h2>Tests de composants React</h2>
            <p>Pour tester des composants React, vous pouvez utiliser <code>@testing-library/react</code> :</p>
            <pre>
                <code>
                    import { render, screen } from '@testing-library/react';
                    import MyComponent from './MyComponent';

                    test('affiche le texte correct', () => {
                        render(<MyComponent />);
                        const linkElement = screen.getByText(/mon composant/i);
                        expect(linkElement).toBeInTheDocument();
                    });
                </code>
            </pre>
        `,
        category: "testing",
    },
    {
        id: 5,
        githubLink:"https://github.com/kajeiou/Fleet-Django",
        title: "Mes premiers pas avec Django : Créer une application de gestion de flotte automobile",
        description: "Comment créer une application de gestion de flotte automobile avec Django, de la configuration initiale à la création de modèles, de vues, et de templates.",
        technologies: ["Django", "Python", "PostgreSQL"],
        creationDate: "2024-11-04", 
        lastModifiedDate: "2024-11-05",
        content: `
            <p>Le but de ce projet est de réaliser une application de gestion de flotte automobile avec Django. Cet article vous guidera à travers les étapes de création, de la configuration initiale à la mise en place des modèles, des vues et des templates.</p>
    
            <h2>Configuration de l'environnement</h2>
            <p>Commencez par créer un environnement virtuel et installez Django :</p>
            <pre>
                <code>
                    python -m venv env
                    env\\Scripts\\activate
                    python.exe -m pip install --upgrade pip
                    pip install Django
                </code>
            </pre>

            <p>Il faut créer un fichier <code>requirements.txt</code> qui contient les dépendances du projet</p>
            <pre>
                <code>
                    Django==5.1.2
                    psycopg2-binary==2.9.6
                </code>
            </pre>
    
            <h2>Création du projet Django</h2>
            <p>Créez le projet et accédez au dossier principal :</p>
            <pre>
                <code>
                    django-admin startproject fleetmaster
                    cd fleetmaster
                </code>
            </pre>
            <p>Le dossier <code>fleetmaster/</code> contient la configuration principale du projet. Créez ensuite l'application :</p>
            <pre>
                <code>
                    python manage.py startapp fleet
                </code>
            </pre>
            <p>Ajoutez l'application <code>fleet</code> à <code>INSTALLED_APPS</code> dans <code>fleetmaster/settings.py</code> :</p>
            <pre>
                <code>
                    INSTALLED_APPS = [
                        'django.contrib.admin',
                        'django.contrib.auth',
                        'django.contrib.contenttypes',
                        'django.contrib.sessions',
                        'django.contrib.messages',
                        'django.contrib.staticfiles',
                        'fleet',  // Ajoutez l'application ici
                    ]
                </code>
            </pre>
    
            <h2>Configuration de la base de données</h2>
            <p>Installez PostgreSQL et le connecteur Python :</p>
            <pre>
                <code>
                    pip install psycopg2-binary
                </code>
            </pre>
            <p>Modifiez la configuration de la base de données dans <code>fleetmaster/settings.py</code> :</p>
            <pre>
                <code>
                    DATABASES = {
                        'default': {
                            'ENGINE': 'django.db.backends.postgresql',
                            'NAME': 'fleetmaster_db',
                            'USER': 'fleetmaster',
                            'PASSWORD': 'wxcvbn',
                            'HOST': 'localhost',
                            'PORT': '5432',
                        }
                    }
                </code>
            </pre>
            <p>Exécutez la migration initiale (10 tables sont créées dans la base de données) :</p>
            <pre>
                <code>
                    python manage.py migrate
                </code>
            </pre>
            <img src="/assets/articles/5/tables.png" alt="Database PostgreSQL" style="max-width: 100%; height: auto; margin: 10px 0;">
            <p>Créez un superutilisateur :</p>
            <pre>
                <code>
                    python manage.py createsuperuser
                </code>
            </pre>
            <p>Lancez le serveur de développement :</p>
            <pre>
                <code>
                    python manage.py runserver
                </code>
            </pre>
            <p>L'interface d'administration est accessible à <code>localhost:8000/admin</code>.</p>
            <img src="/assets/articles/5/administration_django.png" alt="Home Page" style="max-width: 100%; height: auto; margin: 10px 0;">
    
            <h2>Création des modèles</h2>
            <p>Définissez les modèles dans <code>fleet/models.py</code> :</p>
            <pre>
                <code>
                    from django.db import models
                    from django.contrib.auth.models import User
    
                    class Vehicle(models.Model):
                        owner = models.ForeignKey(User, on_delete=models.CASCADE)
                        make = models.CharField(max_length=50)
                        model = models.CharField(max_length=50)
                        year = models.IntegerField()
                        license_plate = models.CharField(max_length=20, unique=True)
                        mileage = models.IntegerField(default=0)
                        last_service_date = models.DateField(null=True, blank=True)
    
                        def __str__(self):
                            return f"{self.make} {self.model} ({self.license_plate})"
    
                    class MaintenanceRecord(models.Model):
                        vehicle = models.ForeignKey(Vehicle, on_delete=models.CASCADE, related_name='maintenance_records')
                        service_date = models.DateField()
                        description = models.TextField()
                        cost = models.DecimalField(max_digits=10, decimal_places=2)
    
                        def __str__(self):
                            return f"Service on {self.service_date} for {self.vehicle.license_plate}"
                </code>
            </pre>
            <p>Effectuez la migration :</p>
            <pre>
                <code>
                    python manage.py makemigrations
                    python manage.py migrate
                </code>
            </pre>
    
            <h2>Création des vues et des templates</h2>
            <p>Pour organiser le projet, nous allons regrouper nos templates dans un dossier <code>fleet/templates</code></p>
            Pour simplifier, on peut spécifier dans <code>fleetmaster/settings.py</code> le dossier /templates de cette façon :
            <pre>
                <code>
                    import os
                    TEMPLATES = [
                        {
                            'BACKEND': 'django.template.backends.django.DjangoTemplates',
                            'DIRS': [os.path.join(BASE_DIR, 'templates')],
                            'APP_DIRS': True,
                            'OPTIONS': {
                                'context_processors': [
                                    'django.template.context_processors.debug',
                                    'django.template.context_processors.request',
                                    'django.contrib.auth.context_processors.auth',
                                    'django.contrib.messages.context_processors.messages',
                                ],
                            },
                        },
                    ]
                </code>
            </pre>
            <p>Créez des vues basiques dans <code>fleet/views.py</code> :</p>
            <pre>
                <code>
                    from django.shortcuts import render, get_object_or_404
                    from .models import Vehicle
    
                    def vehicle_list(request):
                        vehicles = Vehicle.objects.filter(owner=request.user)
                        return render(request, 'vehicle_list.html', {'vehicles': vehicles})

                    def vehicle_detail(request, pk):
                        vehicle = get_object_or_404(Vehicle, pk=pk, owner=request.user)
                        return render(request, 'vehicle_detail.html', {'vehicle': vehicle})
                </code>
            </pre>
            <p>Créez les templates HTML dans <code>fleet/templates/</code> :</p>
            <ul>
                <li><code>vehicle_list.html</code> pour afficher la liste des véhicules.</li>
                <li><code>vehicle_detail.html</code> pour afficher les détails d'un véhicule.</li>
            </ul>
            <img src="/assets/articles/5/home.png" alt="Home Page" style="max-width: 100%; height: auto; margin: 10px 0;">
            <h2>Création du formulaire</h2>
            <p>Créez un formulaire pour ajouter un véhicule dans <code>fleet/forms.py</code> :</p>
            <pre>
                <code>
                    from django import forms
                    from .models import Vehicle

                    class VehicleForm(forms.ModelForm):
                        class Meta:
                            model = Vehicle
                            fields = ['make', 'model', 'year', 'license_plate', 'mileage', 'last_service_date']
                </code>
            </pre>

            <h2>Création des templates pour ajouter un véhicule</h2>
            <p>Ajoutez la fonction add_vehicle dans <code>fleet/views.py</code></p>
            <pre>
                <code>
                    from django.shortcuts import render, get_object_or_404, redirect
                    from .models import Vehicle
                    from .forms import VehicleForm

                    def add_vehicle(request):
                        if request.method == 'POST':
                            form = VehicleForm(request.POST)
                            if form.is_valid():
                                vehicle = form.save(commit=False)
                                vehicle.owner = request.user  
                                vehicle.save()
                                return redirect('vehicle_list')  
                        else:
                            form = VehicleForm()
                        
                        return render(request, 'add_vehicle.html', {'form': form})
                </code>
            </pre>

            <p>Créez le template <code>add_vehicle.html</code> dans <code>fleet/templates/</code> :</p>
            <pre>
                <code>
                    &lt;h1&gt;Ajouter un véhicule&lt;/h1&gt;
                    &lt;form method="post"&gt;
                        {% csrf_token %}
                        {{ form.as_p }}
                        &lt;button type="submit"&gt;Ajouter&lt;/button&gt;
                    &lt;/form&gt;
                </code>
            </pre>
            <p>Ajoutez la route pour cette vue dans <code>fleet/urls.py</code> :</p>
            <pre>
                <code>
                    from django.urls import path
                    from . import views

                    urlpatterns = [
                        path('vehicles/add/', add_vehicle, name='add_vehicle'),
                        path('', vehicle_list, name='vehicle_list'),
                        path('&lt;int:pk&gt;/', vehicle_detail, name='vehicle_detail'),
                    ]
                </code>
            </pre>
            <img src="/assets/articles/5/add_vehicle_form.png" alt="Home Page" style="max-width: 100%; height: auto; margin: 10px 0;">
            <img src="/assets/articles/5/add_vehicle_form_result.png" alt="Home Page" style="max-width: 100%; height: auto; margin: 10px 0;"><br>
            <img src="/assets/articles/5/detail_vehicle.png" alt="Home Page" style="max-width: 100%; height: auto; margin: 10px 0;">

        `,
        category: "frameworks",
    },
    {
        id: 6,
        githubLink:"https://github.com/kajeiou/",
        title: "Dockeriser une application Django avec Docker",
        description: "Comment containeriser une application avec Docker",
        technologies: ["Docker"],
        creationDate: "2024-11-05", 
        lastModifiedDate: "2024-11-05",
        category: "devops",
        content: `
        <p>Pour dockeriser une application Django, </p>
    
            <h2>Configuration de l'environnement</h2>
            <p>Il faut en premier créer un fichier Dockerfile qui contiendra les instructions  pour construire l'image Docker.</p>
            <pre>
                <code>
                    # Utiliser une image Python comme base
                    FROM python:3.10-slim

                    # Définir le répertoire de travail
                    WORKDIR /app

                    # Copier le fichier requirements.txt et installer les dépendances
                    COPY requirements.txt .
                    RUN pip install --no-cache-dir -r requirements.txt

                    # Copier le code source dans l'image
                    COPY . .

                    # Exposer le port sur lequel l'application va tourner
                    EXPOSE 8000

                    # Lancer le serveur de développement
                    CMD ["python", "manage.py", "runserver", "0.0.0.0:8000"]
                </code>
            </pre>
            <p>Le fichier <code>init_db.sql</code></p>
            <pre>
                <code>
                DO $$
                BEGIN
                    IF NOT EXISTS (SELECT 1 FROM pg_roles WHERE rolname = 'fleetmaster') THEN
                        CREATE USER fleetmaster WITH PASSWORD 'wxcvbn';
                    END IF;
                END $$;

                </code>
            </pre>
            <p>Voici le fichier <code>init_servers.json</code> pour PgAdmin4 :
            <pre>
            <code>

            {
                "Servers": {
                    "1": {
                        "Name": "FleetmasterDB",
                        "Group": "Servers",
                        "Port": 5432,
                        "Host": "db",
                        "Username": "fleetmaster",
                        "Password": "wxcvbn",
                        "SSLMode": "prefer"
                    }
                }
            }

            
            </code>
            </pre>


            <p>Voici le fichier <code>docker-compose.yml</code> :
            <pre>
            <code>
                services:
                    db:
                        image: postgres
                        environment:
                            POSTGRES_DB: fleetmaster_db
                            POSTGRES_USER: fleetmaster
                            POSTGRES_PASSWORD: wxcvbn
                        ports:
                            - "5432:5432"
                        volumes:
                            - ./init_db.sql:/docker-entrypoint-initdb.d/init_db.sql

                    web:
                        build: .
                        ports:
                            - "8000:8000"
                        volumes:
                            - .:/app
                        environment:
                            - DEBUG=True
                            - DB_NAME=fleetmaster_db
                            - DB_USER=fleetmaster
                            - DB_PASSWORD=wxcvbn
                            - DB_HOST=db
                            - DB_PORT=5432
                        depends_on:
                            - db  
                        command: >
                            sh -c "python manage.py migrate && python manage.py runserver 0.0.0.0:8000"

                    pgadmin:
                        image: dpage/pgadmin4
                        environment:
                            PGADMIN_DEFAULT_EMAIL: nabilkajeiou@gmail.com
                            PGADMIN_DEFAULT_PASSWORD: wxcvbn
                        ports:
                            - "5050:80"
                        volumes:
                            - pgadmin_data:/var/lib/pgadmin

                volumes:
                    pgadmin_data:
            </code>
            </pre>

            <p>Il faut maintenant construire les conteneurs avec </p>
            <pre>
            <code>
                docker-compose up --build
            </code>
            </pre>
        
        `,
    },
    {
        id: 7,
        githubLink:"https://github.com/kajeiou/",
        title: "Utiliser Jenkins pour le CI/CD ",
        description: "Découvrez comment configurer et utiliser Jenkins",
        technologies: ["Jenkins"],
        creationDate: "2024-11-05", 
        lastModifiedDate: "2024-11-05",
        category: "devops",
        content: ``,
    },
    {
        id: 8,
        githubLink:"https://github.com/kajeiou/",
        title: "Créer une application de chat en ligne avec WebRTC, Node.js et React",
        description: "Créer une communication bidirectionnelle en temps réel",
        technologies: ["React", "WebRTC", "WebSocket", "Nodejs"],
        creationDate: "2024-11-06", 
        lastModifiedDate: "2024-11-06",
        category: "frameworks",
        content: `
        <p>WebRTC (Web Real-Time Communication) est une technologie open source qui permet la communication en temps réel (audio, vidéo et données) entre navigateurs web et appareils sans nécessiter de plugins ou de logiciels tiers. WebRTC est particulièrement populaire pour les applications de chat en ligne, les appels vidéo, la visioconférence, et le partage de données en temps réel.</p>

<p>Voici comment WebRTC permet d'établir un chat en ligne avec des utilisateurs :</p>

<ol>
  <li><strong>Connexion directe entre utilisateurs</strong><br>
  WebRTC permet une communication directe (peer-to-peer) entre les utilisateurs, ce qui élimine la nécessité d'un serveur central pour transmettre les messages ou les flux de données. Cela signifie que le chat peut se dérouler directement entre les navigateurs des utilisateurs, ce qui réduit la latence et améliore la qualité de l'échange.</li>

  <li><strong>Établissement de la connexion avec un serveur de signalisation</strong><br>
  Bien que WebRTC permette la communication directe entre les utilisateurs, il nécessite un serveur de signalisation pour établir et gérer la connexion initiale. Ce serveur (qui n'est pas spécifiquement inclus dans WebRTC) permet aux navigateurs de s'échanger des informations nécessaires pour établir la connexion, telles que les adresses IP et les informations de transport réseau.</li>

  <li><strong>Échange de messages de signalisation</strong><br>
  Lorsqu'un utilisateur souhaite initier un chat avec un autre, un message de signalisation est envoyé pour indiquer la tentative de connexion. Cela inclut des informations sur la session WebRTC, comme la négociation de la session (SDP - Session Description Protocol) et les candidats ICE (Interactive Connectivity Establishment) pour aider à établir une connexion réseau.</li>

  <li><strong>Transmission des données</strong><br>
  Une fois la connexion établie, WebRTC utilise des canaux de données sécurisés pour permettre le transfert de messages textuels en temps réel. Ces messages peuvent être envoyés et reçus via le canal de données (DataChannel) de WebRTC, un mécanisme qui offre une communication rapide et sécurisée.</li>

  <li><strong>Chiffrement intégré</strong><br>
  Une des forces de WebRTC est sa sécurité. WebRTC chiffre les communications par défaut (audio, vidéo et données), ce qui garantit la confidentialité des échanges. Ainsi, le chat en ligne est sécurisé, et les utilisateurs peuvent échanger en toute confiance.</li>

  <li><strong>Compatibilité avec les navigateurs</strong><br>
  WebRTC est pris en charge nativement par la plupart des navigateurs modernes, tels que Google Chrome, Firefox, Safari, et Microsoft Edge, ce qui permet une expérience fluide sans nécessiter de plugins externes.</li>
</ol>

<p>Pour créer un serveur WebSocket de base, voici les étapes à suivre :</p>

<pre>
    <code>
        npm init -y
        npm install express socket.io
    </code>
</pre>

<p>Ensuite, créez le fichier <code>index.js</code> et ajoutez-y le code suivant :</p>

<pre>
    <code>
        const express = require('express');
        const http = require('http');
        const { Server } = require('socket.io');

        const app = express();
        const server = http.createServer(app);
        const io = new Server(server, {
            cors: {
                origin: "*",
            },
        });

        io.on('connection', (socket) => {
            console.log('Un utilisateur est connecté :', socket.id);

            socket.on('message', (message) => {
                socket.broadcast.emit('message', message);
            });

            socket.on('disconnect', () => {
                console.log('Un utilisateur est déconnecté :', socket.id);
            });
        });

        const PORT = 4000;
        server.listen(PORT, () => {
            console.log('Serveur WebSocket en écoute sur le port', PORT);
        });
    </code>
</pre>

<p>Explications :</p>

<ul>
    <li><strong>npm init -y</strong> : Cette commande initialise un projet Node.js et crée un fichier <code>package.json</code> avec les paramètres par défaut (grâce à l'option <code>-y</code>).</li>
    <li><strong>npm install express socket.io</strong> : Installe les dépendances <code>express</code> et <code>socket.io</code>, nécessaires pour créer le serveur et gérer les connexions WebSocket.</li>
    <li><strong>const app = express()</strong> : Crée une instance d'Express, un framework minimaliste pour gérer les requêtes HTTP.</li>
    <li><strong>const server = http.createServer(app)</strong> : Crée un serveur HTTP en utilisant l'instance d'Express, permettant de gérer les requêtes WebSocket.</li>
    <li><strong>const io = new Server(server, { cors: { origin: "*" } })</strong> : Crée une instance de Socket.IO liée au serveur HTTP. L'option <code>cors</code> permet d'autoriser les connexions de toutes les origines (utile pour les applications front-end hébergées ailleurs).</li>
    <li><strong>io.on('connection', (socket) => { ... })</strong> : Écoute les connexions des clients. Lorsqu'un client se connecte, il déclenche l'événement <code>'connection'</code> et un identifiant unique est attribué à la session de socket.</li>
    <li><strong>socket.on('message', (message) => { ... })</strong> : Permet au client d'envoyer des messages au serveur. Le message est ensuite retransmis à tous les autres clients connectés via <code>socket.broadcast.emit</code>.</li>
    <li><strong>socket.on('disconnect', () => { ... })</strong> : Écoute la déconnexion d'un utilisateur. Lorsque cela se produit, un message est affiché dans la console.</li>
    <li><strong>server.listen(PORT, () => { ... })</strong> : Démarre le serveur HTTP et écoute sur le port spécifié (ici 4000). Le serveur WebSocket est ainsi en mesure d'accepter les connexions des clients.</li>
</ul>


        <p>Pour initier une application React et installer le paquet nécessaire, suivez ces étapes :</p>

<pre>
    <code>
        npx create-react-app react-js
        npm install socket.io-client
    </code>
</pre>

<p>Ensuite, créez un composant <code>Chat.js</code> dans le répertoire <code>src/</code> :</p>

<pre>
            <code>
                import React, { useEffect, useState } from 'react';
                import { io } from 'socket.io-client';

                const socket = io("http://localhost:4000");

                const Chat = () => {
                const [message, setMessage] = useState('');
                const [messages, setMessages] = useState([]);

                useEffect(() => {
                    socket.on('message', (message) => {
                    setMessages((prevMessages) => [...prevMessages, message]);
                    });

                    return () => {
                    socket.off('message');
                    };
                }, []);

                const sendMessage = () => {
                    socket.emit('message', message);
                    setMessage('');
                };

                return (
                     &lt;div/&gt;
                     &lt;h2/&gt;Chat en temps réel &lt;/h2> 
                     &lt;div/&gt;
                        {messages.map((msg, index) =/&gt; (
                         &lt;p key={index}/&gt;{msg} &lt;/p/&gt;
                        ))}
                     &lt;/div/&gt;
                     &lt;input
                        type="text"
                        value={message}
                        onChange={(e) =/&gt; setMessage(e.target.value)}
                        placeholder="Tapez votre message"
                    /&gt;
                     &lt;button onClick={sendMessage}/&gt;Envoyer &lt;/button/&gt;
                     &lt;/div/&gt;
                );
                };

                export default Chat;

            </code>
        </pre>

<p>Explications :</p>

<ul>
    <li><strong>npx create-react-app react-js</strong> : Crée une nouvelle application React appelée <code>react-js</code> avec tous les fichiers de configuration nécessaires.</li>
    <li><strong>npm install socket.io-client</strong> : Installe le client <code>socket.io</code> qui permet de se connecter à un serveur WebSocket pour échanger des messages en temps réel.</li>
    <li><strong>const socket = io("http://localhost:4000")</strong> : Crée une connexion WebSocket avec le serveur en utilisant l'URL <code>http://localhost:4000</code> (où le serveur WebSocket a été initialisé précédemment).</li>
    <li><strong>useState</strong> : Utilisé pour gérer l'état local du composant, ici pour gérer le message actuel à envoyer (<code>message</code>) et la liste des messages reçus (<code>messages</code>).</li>
    <li><strong>useEffect</strong> : Permet de configurer un écouteur d'événements sur le socket pour recevoir des messages. L'effet est exécuté une seule fois lorsque le composant est monté et se nettoie lors du démontage du composant.</li>
    <li><strong>socket.on('message', ...)</strong> : Écoute les messages envoyés par le serveur et les ajoute à l'état des messages.</li>
    <li><strong>socket.emit('message', message)</strong> : Envoie le message actuel au serveur WebSocket lorsque l'utilisateur clique sur le bouton "Envoyer".</li>
    <li><strong>return ()</strong> : Le JSX retourné par le composant qui affiche l'interface de chat, y compris un champ de saisie pour le message et un bouton pour l'envoyer.</li>
</ul>

<p>Ensuite, utilisez ce composant dans le fichier principal <code>App.js</code> :</p>

        <pre>
            <code>
                import React from 'react';
                import Chat from './Chat';

                function App() {
                return (&lt;Chat /&gt;);
                }

                export default App;
            </code>
        </pre>


        <img src="/assets/articles/8/websocket1.png" alt="WebSocket Image Test 1" style="max-width: 100%; height: auto; margin: 10px 0;">
        <img src="/assets/articles/8/websocket2.png" alt="WebSocket Image Test 2" style="max-width: 100%; height: auto; margin: 10px 0;">


        `,
    }
    
];

export default articles;
