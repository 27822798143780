import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import Nav from './components/Nav';
import Skills from './components/Skills';
import Projects from './components/Projects';
import Footer from './components/Footer';
import Contact from './components/Contact';
import Formations from './components/Formations';
import Experiences from './components/Experiences';
import Articles from './components/Articles';
import ArticleDetail from './components/ArticleDetail';
import { Box, ThemeProvider } from '@mui/material';
import 'bootstrap/dist/css/bootstrap.min.css';
import Curriculum from './components/Curriculum';
import './App.css';
import blueTheme from './themes/theme'; 
import { motion } from 'framer-motion';
import Tools from './components/Tools';

function App() {
  return (
    <div className="app-background">
      <Router>
        <ThemeProvider theme={blueTheme}> 
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              minHeight: '100vh',
            }}
          >
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1 }}
            >
              <Nav />
            </motion.div>
            <Box
              component="main"
              sx={{
                flexGrow: 1,
                position: 'relative', 
                zIndex: 1,
              }}
            >
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/experiences" element={<Experiences />} />
                <Route path="/formations" element={<Formations />} />
                <Route path="/competences" element={<Skills />} />
                <Route path="/projects" element={<Projects />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/curriculum" element={<Curriculum />} />
                <Route path="/articles" element={<Articles />} />
                <Route path="/articles/:id" element={<ArticleDetail />} />
                <Route path="/outils" element={<Tools />} />
              </Routes>
            </Box>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1 }}
            >
              <Footer />
            </motion.div>
          </Box>
        </ThemeProvider>
      </Router>
    </div>
  );
}

export default App;
