import { Box, Typography, Card, CardContent, CardMedia, Button, useTheme } from '@mui/material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Carousel from 'react-bootstrap/Carousel';
import { Link } from 'react-router-dom';
import projects from '../../data/projectsData';
import { useInView } from 'react-intersection-observer';
import '../Home.css'; 

export default function HomeProjects () {
    const theme = useTheme(); 
    const { ref: projectsRef, inView: projectsInView } = useInView({ threshold: 0.1 });

    return (
        <Box
            ref={projectsRef}
            className={`fade-in ${projectsInView ? 'visible' : ''}`}
            sx={{ marginBottom: 8, padding: 4, borderRadius: '8px', width: '90%', textAlign: 'center' }}
        >

            <Typography variant="h4" gutterBottom sx={{ color: theme.palette.primary.main, fontWeight: 'bold',  }}>
                MES PROJETS
            </Typography>
            <Carousel style={{ marginTop: '20px', }} 
                nextIcon={<span className="carousel-control-next-icon" style={{ filter: 'invert(100%)', }} />}
                prevIcon={<span className="carousel-control-prev-icon" style={{ filter: 'invert(100%)' }} />}
                indicators={true}
                interval={2000}
                >
                {projects.map((project, index) => (
                    <Carousel.Item key={index}>
                        <Card sx={{ backgroundColor: 'transparent', marginBottom: 6 }}>
                            <CardMedia component="img" alt={project.title} height="400" image={project.image} />
                            <CardContent>
                                <Typography variant="h5" sx={{ color: theme.palette.primary.main}}>{project.title}</Typography>
                                <Typography variant="body2" sx={{ color: theme.palette.primary.main}}>{project.description}</Typography>
                            </CardContent>
                        </Card>
                    </Carousel.Item>
                ))}
            </Carousel>
            <Link to="/projects" style={{ textDecoration: 'none', color: 'inherit' }}>
                <Button variant="contained" sx={{ mt: 1, mr: 1, backgroundColor: theme.palette.primary.main, color: 'white' }}>
                    <RemoveRedEyeIcon sx={{ marginRight: 2 }} />Voir tous mes projets
                </Button>
            </Link>
        </Box>
    )
}