import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';

export default function Weather() {
  const theme = useTheme();

  return (
    <Box sx={{ padding: '20px', textAlign: 'center' }}>
      <Typography variant="h6" gutterBottom sx={{ color: theme.palette.primary.main, fontWeight: 'bold' }}>
        Météo des 30 jours à venir
      </Typography>
      <Box>
        
      </Box>
    </Box>
  );
}
