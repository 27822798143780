import { Box} from '@mui/material';
import Experiences from '../Experiences';
import { useInView } from 'react-intersection-observer';

export default function HomeExperiences () {
    const { ref: experiencesRef, inView: experiencesInView } = useInView({ threshold: 0.1 });
    return (
        <Box
            ref={experiencesRef}
            className={`fade-in ${experiencesInView ? 'visible' : ''}`} 
            sx={{ marginBottom: 8, borderRadius: '8px', width: '90%', textAlign: 'center' }}
        >
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 4 }}>
                <Experiences />
            </Box>
        </Box>
    )
}