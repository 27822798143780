import React, { useState } from 'react';
import { Box, Stepper, Step, StepLabel, StepContent, Button, Typography, useTheme, Paper, Fade } from '@mui/material';
import experiences from '../data/experiencesData';
import { motion } from 'framer-motion'; 

export default function Experiences() {
  const [activeStep, setActiveStep] = useState(0);
  const theme = useTheme(); 

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Box sx={{ maxWidth: { xs: '100%', sm: '1000px' }, textAlign: 'center', mx: 'auto', padding: '20px' }}>
      <Typography variant="h4" gutterBottom sx={{ color: theme.palette.primary.main, fontWeight: 'bold',  }}>
        MES EXPERIENCES
      </Typography>

      
      <Stepper activeStep={activeStep} orientation="vertical">
        {experiences.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              StepIconComponent={() => (
                <motion.div
                  initial={{ scale: 0 }}
                  animate={{ scale: activeStep === index ? 1.2 : 1 }}
                  transition={{ type: 'spring', stiffness: 400, damping: 10 }}
                  style={{
                    backgroundColor: activeStep === index ? theme.palette.primary.main : 'gray',
                    color: 'white',
                    borderRadius: '50%',
                    width: 30,
                    height: 30,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {index + 1}
                </motion.div>
              )}
              optional={
                <Typography variant="caption" sx={{ fontSize: '16px', mb: 1 }}>
                  {step.date}
                </Typography>
              }
            >
              
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', width: '100%' }}>
                <Box
                  component="img"
                  src={step.logoUrl}
                  alt={step.company}
                  sx={{
                    width: 50, 
                    height: 50, 
                    marginRight: 2,
                    objectFit: 'contain', 
                    borderRadius: "10px", 
                  }}
                />
                <Typography variant="h6" sx={{ fontWeight: 'bold', color: theme.palette.primary.main }}>
                  {step.label}
                </Typography>
              </Box>

            </StepLabel>
            
            <StepContent>
              <Fade in={true} timeout={500}>
                <Typography
                  sx={{
                    mb: 2,
                    textAlign: 'left',
                    overflow: 'hidden',
                    whiteSpace: 'pre-line',
                    wordWrap: 'break-word',
                    fontSize: '16px',
                    color: theme.palette.text.secondary,
                  }}
                >
                  {step.description}
                </Typography>
              </Fade>
              <Box sx={{ mb: 2 }}>
                <Button
                  variant="contained"
                  onClick={handleNext}
                  sx={{
                    mt: 1,
                    mr: 1,
                    backgroundColor: theme.palette.primary.main,
                    color: 'white',
                    '&:hover': { backgroundColor: theme.palette.primary.dark }
                  }}
                >
                  {index === experiences.length - 1 ? 'Fermer' : 'Continuer'}
                </Button>
                <Button
                  disabled={index === 0}
                  onClick={handleBack}
                  sx={{
                    mt: 1,
                    mr: 1,
                    color: theme.palette.primary.main,
                    '&:hover': { backgroundColor: theme.palette.primary.light }
                  }}
                >
                  Retour
                </Button>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      
      {activeStep === experiences.length && (
        <Paper square elevation={0} sx={{ p: 3, backgroundColor: 'transparent' }}>
          <Typography sx={{ fontSize: '16px', color: theme.palette.text.primary }}>
            Enfin, je compte 3 ans d'alternance, 1 an en tant qu'indépendant, et 2 mois de stage.
          </Typography>
          <Button
            onClick={handleReset}
            sx={{
              mt: 1,
              mr: 1,
              color: theme.palette.primary.main,
              '&:hover': { backgroundColor: theme.palette.primary.light, color: theme.palette.text.primary }
            }}
          >
            Refaire un tour sur mes expériences
          </Button>
        </Paper>
      )}
    </Box>
  );
}
